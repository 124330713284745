import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../../shared/constants/ApiConstants";
import { api } from "../../shared/BaseApi";
import sprintf from "sprintf";

const SORTING_RULES_URL = `${API_ROOT_URL}/admin/sorting/rules`;
const SORTING_RULES_REORDER_URL = `${API_ROOT_URL}/admin/sorting/rules/reorder`;
const SORTING_SHIPMENT_ORDERS_LIST_URL = `${API_ROOT_URL}/admin/sorting/shipments/orders`;
const SORTING_SHIPMENT_SIMPLE_LIST_URL = `${API_ROOT_URL}/admin/sorting/shipments/simple`;
const SORTING_SHIPMENT_CONSOLIDATED_LIST_URL = `${API_ROOT_URL}/admin/sorting/shipments/consolidated`;
const SORTING_SHIPMENT_CONTAINER_LIST_URL = `${API_ROOT_URL}/admin/sorting/shipments/container`;
const SORTING_SHIPMENT_TOTAL_URL = `${API_ROOT_URL}/admin/sorting/shipments/totals`;
const SORTING_RULES_ITEM_URL = `${API_ROOT_URL}/admin/sorting/rules/%s`;
const SORTING_BATCH_UPDATE_ITEM_URL = `${API_ROOT_URL}/admin/sorting/shipments/new`;
const SORTING_SHIPMENT_SEARCH_URL = `${API_ROOT_URL}/admin/sorting/shipments/search`;
const SORTING_SHIPMENT_SYNC_URL = `${API_ROOT_URL}/admin/sorting/shipments/sync`;
const SORTING_SHIPMENT_CLEAR_URL = `${API_ROOT_URL}/admin/sorting/shipments/clear`;

export const getSortingRules = params => api.get(SORTING_RULES_URL, { params });

export const getSortingShipmentOrders = body =>
  api.post(SORTING_SHIPMENT_ORDERS_LIST_URL, {
    body,
  });

export const getSortingBatchUpdateItem = body =>
  api.post(SORTING_BATCH_UPDATE_ITEM_URL, {
    body,
  });

export const getSortingShipmentSimple = body =>
  api.post(SORTING_SHIPMENT_SIMPLE_LIST_URL, {
    body,
  });
export const getSortingShipmentConsolidated = body =>
  api.post(SORTING_SHIPMENT_CONSOLIDATED_LIST_URL, {
    body,
  });
export const getSortingShipmentContainer = body =>
  api.postStream(SORTING_SHIPMENT_CONTAINER_LIST_URL, {
    body,
  });

export const reorderSortingRules = ids =>
  api.post(SORTING_RULES_REORDER_URL, {
    body: {
      ids,
    },
  });

export const totalSortingShipment = () =>
  api.post(SORTING_SHIPMENT_TOTAL_URL, { body: {} });

export const getRuleItem = id => api.get(sprintf(SORTING_RULES_ITEM_URL, id));

export const deleteRuleItem = id =>
  api.delete(sprintf(SORTING_RULES_ITEM_URL, id));

export const updateRule = (id, body) =>
  api.put(sprintf(SORTING_RULES_ITEM_URL, id), {
    body,
  });

export const createRule = body =>
  api.post(SORTING_RULES_URL, {
    body,
  });

export const getShipmentSearch = barcode =>
  api.get(SORTING_SHIPMENT_SEARCH_URL, {
    params: {
      barcode,
    },
  });

export const syncShipment = () => api.get(SORTING_SHIPMENT_SYNC_URL);
export const clearShipment = body =>
  api.post(SORTING_SHIPMENT_CLEAR_URL, {
    body,
  });

// inbound

const SORTING_INBOUND_SCAN_URL = `${API_ROOT_URL}/admin/sorting/inbound/scan`;
const SORTING_INBOUND_CLEAR_URL = `${API_ROOT_URL}/admin/sorting/inbound/clear`;
const SORTING_INBOUND_COMPLETE_URL = `${API_ROOT_URL}/admin/sorting/inbound/complete`;
const SORTING_INBOUND_OPEN_URL = `${API_ROOT_URL}/admin/sorting/inbound/%s/open`;

export const inboundScan = body =>
  api.post(SORTING_INBOUND_SCAN_URL, {
    body,
  });

export const inboundClear = () =>
  api.post(SORTING_INBOUND_CLEAR_URL, { body: {} });

export const inboundComplete = () =>
  api.post(SORTING_INBOUND_COMPLETE_URL, { body: {} });

export const inboundOpen = number =>
  api.post(sprintf(SORTING_INBOUND_OPEN_URL, number));

const ADMIN_SORTING_SHIPMENTS_BARCODES_URL = `${API_ROOT_URL}/admin/sorting/shipments/barcodes`;
const ORDER_SHIFT_HANDOVER_URL = `${API_ROOT_URL}/order_shift/handover`;
const ORDER_SHIFT_ACCEPT_URL = `${API_ROOT_URL}/order_shift/accept`;
const ORDER_SHIFT_ITEM_URL = `${API_ROOT_URL}/order_shift/%s`;
const ORDER_SHIFT_LIST_URL = `${API_ROOT_URL}/order_shift/all`;
const CHECK_VERIFY_ORDER_URL = `${API_ROOT_URL}/order_shift/verify`;

export const HANDOVER_PDF_URL = `${API_V2_ROOT_URL}/handover`;
export const ACCEPT_PDF_URL = `${API_V2_ROOT_URL}/accept`;

export const getShipmentsBarcodes = () =>
  api.get(ADMIN_SORTING_SHIPMENTS_BARCODES_URL);

export const saveOrderShiftHandover = body =>
  api.post(ORDER_SHIFT_HANDOVER_URL, { body });

export const saveOrderShiftAccept = body =>
  api.post(ORDER_SHIFT_ACCEPT_URL, { body });

export const getOrderShiftItem = id =>
  api.get(sprintf(ORDER_SHIFT_ITEM_URL, id));

export const getOrderShiftList = params =>
  api.get(ORDER_SHIFT_LIST_URL, { params });

export const checkVerifyOrder = barcode =>
  api.get(CHECK_VERIFY_ORDER_URL, {
    params: {
      barcode,
    },
  });

// Customs checkout

const CUSTOMS_CHECKOUT_URL = `${API_ROOT_URL}/admin/customs/checkout`;
const CUSTOMS_CHECKOUT_ITEM_URL = `${API_ROOT_URL}/admin/customs/checkout/%s`;

export const getCustomsCheckoutList = params =>
  api.get(CUSTOMS_CHECKOUT_URL, { params });

export const createCustomsCheckout = body =>
  api.post(CUSTOMS_CHECKOUT_URL, { body });

export const deleteCustomsCheckout = id =>
  api.delete(sprintf(CUSTOMS_CHECKOUT_ITEM_URL, id));
